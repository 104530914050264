import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import LangService from "@/commons/service/langService";

Vue.use(VueI18n);

const loadedLanguages = [];
const messages = {};

const i18n = new VueI18n({
  locale: "it",
  fallbackLocale: "it",
  messages: messages,
  numberFormats: loadNumberFormats()
});

export default i18n;
export async function setLocale(lang) {
 
    if (!loadedLanguages.includes(lang)) {
      let res = await loadLocaleMessagesFromEbsn(lang);
      if(res == null || res[lang] == null || res[lang].login == null){
        res = await loadLocaleMessagesFromEbsn(lang);
      }
      if (res == null || res[lang] == null || res[lang].login == null) {
        console.log("KO - ebsn getLabels failed, load form it.json");
        loadLocaleMessageFromLocal(lang);
      } else {
        console.log("OK - labels correctly loaded from ebsn");
      }
      setI18nLanguage(lang);
    }
}

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

async function loadLocaleMessagesFromEbsn(lang) {
  if (!lang) {
    lang = "it";
  }
  let response = await LangService.getLang("labels", 1000);
  if (response) {
    loadedLanguages.push(lang);
    messages[lang] = response;

    return messages;
  }
  return null;
}

function loadLocaleMessageFromLocal() {

  const locales = require.context(
    "@/localization",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
function loadNumberFormats() {
  const numberFormats = {
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD"
      }
    },
    it: {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol"
      }
    }
  };
  return numberFormats;
}