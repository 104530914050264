var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advent-calendar" },
    [
      _c("ResponseMessage", {
        staticClass: "mt-3",
        attrs: { response: _vm.response, title: "OPS!" }
      }),
      _c(
        "v-overlay",
        {
          attrs: { opacity: "0.8" },
          model: {
            value: _vm.showOverlay,
            callback: function($$v) {
              _vm.showOverlay = $$v
            },
            expression: "showOverlay"
          }
        },
        [
          _c("img", {
            staticClass: "overlay-img",
            attrs: { src: "/img_layout/advent_calendar/paccho-open.gif" }
          })
        ]
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "calendar-grid" },
        _vm._l(_vm.localCalendar, function(day, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "calendar-day",
              class: {
                "calendar-day-open":
                  day.status === "Redeemed" ||
                  day.status === "Booked" ||
                  day.status === "Expired"
              },
              style: _vm.getBackgroundImage(day),
              on: {
                click: function($event) {
                  return _vm.clickCoupon(day)
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "day-number",
                  class: {
                    "package-opened":
                      day.status === "Redeemed" ||
                      day.status === "Booked" ||
                      day.status === "Expired",
                    "day-number-disabled":
                      day.status === "Redeemed" ||
                      day.status === "Expired" ||
                      day.status === "Missed",
                    "day-number-enabled": !(
                      day.status === "Redeemed" ||
                      day.status === "Expired" ||
                      day.status === "Missed"
                    )
                  }
                },
                [_vm._v(" " + _vm._s(day.day) + " ")]
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticStyle: { width: "70%" },
        attrs: { src: "/img_layout/advent_calendar/logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }