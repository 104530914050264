<template>
  <v-card
    elevation="2"
    class="coupon-card rounded-lg d-flex pa-1"
    :class="type"
    ref="couponCard"
    @click="showDetails"
    v-if="isCouponHidden()"
    :disabled="disabled"
  >
    <div class="d-flex justify-center align-center flex-shrink-1">
      <img
        v-if="type == 'voucher'"
        :src="coupon.categoryProposal.img"
        alt=""
        class="coupon-img rounded-lg"
      />
      <img
        v-else
        :src="
          type == 'activeDiscount' || type == 'welcomeCoupon'
            ? coupon.product.mediaURL
            : coupon.mediaURL
            ? coupon.mediaURL
            : coupon.media[0].small
        "
        onerror="this.onerror=null;this.src='/no-icon.png'"
        class="coupon-img rounded-lg"
        :alt="
          'Coupon-' + (type == 'activeDiscount' ? coupon.barCode : coupon.name)
        "
      />
    </div>
    <div
      v-if="type == 'activeDiscount'"
      class="d-flex flex-column justify-center text-center w-100 ml-2 px-1"
    >
      <div class="text-body-0 font-weight-semibold">
        {{ $t("coupons.discounts.activeVoucher") }}
      </div>
      <div>
        <v-btn
          rounded
          color="primary"
          block
          large
          outlined
          @click.stop="showDetails"
          >{{ $t("coupons.discounts.view") }}</v-btn
        >
      </div>
    </div>
    <div
      v-else-if="type == 'voucher' || type == 'welcomeCoupon'"
      class="d-flex flex-column justify-center text-center w-100 ml-2 px-1"
    >
      <div
        :class="{
          'flex-column': type != 'discount'
        }"
        class="coupon-description d-flex justify-center align-center"
      >
        <div v-if="type == 'voucher'" class="font-weight-semibold text-body-2">
          {{ $t("coupons.discounts.discountCoupon") }}
        </div>
        <div
          v-if="type == 'welcomeCoupon'"
          class="font-weight-semibold text-body-2"
        >
          {{ $t(title) }}
        </div>
        <div v-if="type == 'voucher'" class="discount-name">
          {{ amount }}{{ discountType }}
        </div>
        <div v-if="type == 'welcomeCoupon'" class="discount-name">
          {{ coupon.product?.name }}
        </div>
      </div>
      <div>
        <div v-if="type == 'welcomeCoupon'" class="voucher-name">
          {{ $t("coupons.discounts.onYourSpending") }}
        </div>
        <div v-else class="voucher-name">
          {{ $t("coupons.discounts.forYou") }}
        </div>
        <v-btn rounded color="primary" block large @click.stop="showDetails">{{
          $t("coupons.discounts.view")
        }}</v-btn>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 justify-space-around w-100 ml-2 px-1"
    >
      <div
        :class="{
          'flex-column': type != 'discount'
        }"
        class="coupon-description d-flex justify-center align-center"
      >
        <div>
          <div
            v-if="type == 'discount'"
            class="font-weight-semibold text-body-2"
          >
            {{ $t("coupons.discounts.discountCoupon") }}
          </div>
          <div v-if="type == 'donation'" class="text-body-2 text-uppercase">
            {{ $t("coupons.discounts.donatePoints") }}
          </div>
          <div :class="`${type + '-name'}`">
            {{ coupon.name }}
            <v-icon
              style="padding-left: 10%;"
              v-if="type == 'discount' && !details"
              color="grey"
              >$info</v-icon
            >
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-center align-center">
        <div class="font-weight-bold py-1">
          {{ coupon.warehousePromo?.view.bubble }}
        </div>
        <div class="w-100">
          <v-btn
            rounded
            color="primary"
            block
            large
            @click.stop="activateModal"
            >{{ type == "discount" ? "Attiva" : "Dona ora" }}</v-btn
          >
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.coupon-card {
  min-height: 160px;
  .coupon-img {
    width: 150px;
  }
  .coupon-description-no-line {
    border-bottom: none !important;
  }
  .coupon-description {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px dashed $primary;
    .discount-name {
      font-size: 26px;
      font-weight: 600;
      color: $primary;
    }
    .donation-name {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .amount {
      font-size: 24px;
      color: $primary;
    }
  }
  .voucher-name {
    font-weight: 600;
    margin: 5px 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.coupon-card.activeDiscount {
  background-color: var(--v-primary-lighten1) !important;
  .v-btn {
    background-color: $white;
  }
}
</style>
<script>
import CouponsService from "@/service/couponsService";
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";
export default {
  name: "CopuonCard",
  props: {
    coupon: { type: Object, required: true },
    details: { type: Boolean, default: false },
    type: { type: String, required: true },
    title: { type: String, default: "coupons.discounts.discountCoupon" },
    customCssClass: { type: String, default: "" },
    userPoints: { type: Number, default: 0 }
  },
  data() {
    return {
      startDate: new Date(2025, 0 , 19),
      endDate: new Date(2025, 0 , 27),
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    disabled() {
      if (
        this.isAuthenticated &&
        (this.type == "activeDiscount" ||
          this.type == "voucher" ||
          this.type == "welcomeCoupon" ||
          (this.coupon.warehousePromo &&
            this.coupon.warehousePromo.promoPoints &&
            this.coupon.warehousePromo.promoPoints <= this.userPoints))
      ) {
        return false;
      } else return true;
    },
    amount() {
      return get(this.coupon, "userGiftCertificates[0].amount");
    },
    discountType() {
      let type = get(this.coupon, "giftAdjustmentType.descr");
      switch (type) {
        case "PercentOff":
          return "%";
        case "FixedAmounOff":
          return "€";
        default:
          return "";
      }
    }
  },
  methods: {
    ...mapActions({
      updateUserPoints: "custom/updateUserPoints"
    }),
    isInDateRange(startDate, endDate){
      const today = new Date();
      const todayAtMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return (todayAtMidnight >= startDate && todayAtMidnight < endDate);
    },
    isCouponHidden() {
    let isInRange = this.isInDateRange(this.startDate, this.endDate);
    if(!isInRange){
      return true;
    }

    if (!this.isAuthenticated) {
        return false;
    }
    if (this.coupon.warehousePromo === undefined) {
        return true;
    }
    if (this.coupon.warehousePromo.warehousePromoTypeId == 4 && this.type !== 'activeDiscount') {
        return false;
    }

    return true;
},
    async showDetails() {
      console.log(this.coupon);
      this.$emit("showDetails", this.coupon, this.type, this.customCssClass);
    },
    async activateDiscount() {
      CouponsService.activateDiscount(this.coupon.productId,null).then(data => {
        if (data.response.status === 0) {
          this.$emit("reload");
          this.updateUserPoints();
        }
      });
    },
    async activateDonation() {
      CouponsService.activateDonation(this.coupon.productId).then(data => {
        if (data.response.status === 0) {
          this.$emit("reload");
          this.updateUserPoints();
        }
      });
    },
    async activateModal() {
      let title =
        this.type == "discount"
          ? this.$t("coupons.discounts.discountCoupon") + " " + this.coupon.name
          : this.coupon.name;
      let descr = "";
      if (this.type == "discount") {
        descr = `<h2 class='primary--text text-center'>${title}</h2><p class='text-center font-weight-bold'>Sei sicuro di voler attivare <span class='text-uppercase'>${title}?</span></p><p class='text-center'>I tuoi punti verranno scalati immediatamente</p>`;
      } else {
        descr = `<h2 class='primary--text text-center'>${title}</h2><p class='text-center font-weight-bold'>Sei sicuro di voler attivare una donazione per <span class='text-uppercase'>${title}?</span></p><p class='text-center'>I tuoi punti verranno scalati immediatamente</p>`;
      }
      let res = await this.$dialog.confirm({
        text: descr,
        class: "confirm-coupon-activation",
        actions: {
          false: {
            color: "var(--v-primary-base)",
            rounded: true,
            text: "Chiudi"
          },
          true: {
            rounded: true,
            text: this.type == "discount" ? "Attiva" : "Dona ora"
          }
        }
      });
      if (res) {
        if (this.type == "discount") {
          this.activateDiscount();
        } else if (this.type == "donation") {
          this.activateDonation();
        }
      }
    }
  },
  mounted() {}
};
</script>
