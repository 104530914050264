<template>
  <div class="progress-bar-block mx-n2 px-2 py-4 mb-4">
    <TitleRow
      :config="config"
      categoryBlockName="CalendarBarBlock"
      :showMore="true"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_CalendarBarBlock.DESCRIPTION"
      tag="div"
    />
    <ExpenseCalendarBar
      :calendar="calendar"
      :segments="segments"
    />
    <div class="default--text font-weight-regular pt-3">

      {{ $t("calendarBar.weeksCompleted").split(';')[0] }}
      <strong>{{ completed }}</strong>
      {{ $t("calendarBar.weeksCompleted").split(';')[1] }}

    </div>
  </div>
</template>
<style lang="scss">
.progress-bar-block {
  background-color: $white;
  --v-primary-base: #d50d52;
}
</style>
<script>
import ExpenseCalendarBar from "../coupon/ExpenseCalendarBar.vue";
import TitleRow from "./elements/TitleRow.vue";
import CopuonService from "@/service/couponsService";
export default {
  components: { ExpenseCalendarBar, TitleRow },
  name: "CalendarBarBlock",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      segments: [],
      calendar: [],
      dataIni:'',
      dataFin:'',
      loading: false,
      completed:0,
      weeksCompleted:"",
      tab: "discounts"
    };
  },
  computed: {},
  methods: {
    async reload() {
      await this.getCalendarBar();
      //this.weeksCompleted = this.$t('calendarBar.weeksCompleted').replace("{completed}",this.completed)
    },
    async getCalendarBar() {
      console.log(this.$t('calendarBar.segments'));
      this.segments = String(this.$t('calendarBar.segments')).split(';');
      CopuonService.getCashbackCalendar(this.dataIni,this.dataFin).then(data => {
        const value = data.value.weeklyUserExpense;
        console.log(typeof value)
        this.calendar =value.split(";") ;  
        this.calendar.forEach((e)=>{
          if(e=='Y'){
            this.completed+=1;
          }
        })
        console.log(this.calendar);
      });
    },
   getStartAndEndDateOfMonth() {
    // Define a mapping of Italian month names to month numbers
    const monthMap = {
        "Gennaio": 0,
        "Febbraio": 1,
        "Marzo": 2,
        "Aprile": 3,
        "Maggio": 4,
        "Giugno": 5,
        "Luglio": 6,
        "Agosto": 7,
        "Settembre": 8,
        "Ottobre": 9,
        "Novembre": 10,
        "Dicembre": 11
    };
    
    // Get the current year
    const currentYear = new Date().getFullYear();
    
    const monthName = 'Giugno'
    //this.$t('calendarBar.month');
    // Get the month number from the map
    const monthNumber = monthMap[monthName];
    
    if (monthNumber === undefined) {
        throw new Error("Invalid month name");
    }
    
    // Create the start date and end date
    const startDate = new Date(currentYear, monthNumber, 1);
    const endDate = new Date(currentYear, monthNumber + 1, 0);
    
    // Format the dates to YYYY-MM-DD
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    this.dataIni =formatDate(startDate);
    this.dataFin = formatDate(endDate); 
    console.log(this.dataIni);
    console.log(this.dataFin);
    },
  },
  async created() {
    await this.getStartAndEndDateOfMonth();
    await this.reload();
  }
};
</script>
