import axios from "~/plugins/axios";
export default {
  getProgressBar() {
    let url = `/ebsn/api/coupon/getProgressBar`;

    return axios.get(url).then(data => {
      return data.data;
    });
  },
  getCouponsByCategoryId(parentCategoryId) {
    return axios
      .get("/ebsn/api/products", {
        params: {
          store_id: 6,
          parent_category_id: parentCategoryId,
          sort: "weight_des"
        },
        cache: {
          maxAge: 0 // Disable caching
        }
      })
      .then(response => response.data.data)
      .catch(error => error);
  },
  getActiveCopuns() {
    let url = `/ebsn/api/coupon/getActivatedDiscounts`;

    return axios.get(url).then(data => {
      return data.data;
    });
  },
  getVouchers() {
    let url = `/ebsn/api/coupon/getVouchers?type=total&status=available`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  activateDiscount(id,type) {
    let url = `/ebsn/api/coupon/activateDiscount?productId=${id}&type=${type}`;
    return axios.post(url).then(data => {
      if (data.data.response.status == 0) {
        return data.data;
      } else {
        return Promise.reject(data.data);
      }
    });


  },
  activateDonation(id) {
    let url = `/ebsn/api/coupon/activateDonation?productId=${id}`;

    return axios.post(url).then(data => {
      return data.data;
    });
  },
  getCouponBySlug(slug) {
    return axios
      .get("/ebsn/api/products", {
        params: { slug: slug }
      })
      .then(response => response.data.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  getCouponGallery(selected) {
    let url = `/ebsn/api/coupon/getVouchers?type=product&status=available&selected=${selected}`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getAllCouponGallery() {
    let url = `/ebsn/api/coupon/getVouchers?type=product&status=available`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  activateGaleryCoupon(code) {
    let url = `/ebsn/api/coupon/selectVoucher?code=${code}`;

    return axios.post(url).then(data => {
      return data.data;
    });
  },
  deactivateGaleryCoupon(code) {
    let url = `/ebsn/api/coupon/deselectVoucher?code=${code}`;

    return axios.post(url).then(data => {
      return data.data;
    });
  },
  getRedeemedDiscounts() {
    let url = `/ebsn/api/coupon/getRedeemedDiscounts`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getRedeemedTotalCoupons(redemption_dates) {
    let url = `/ebsn/api/coupon/getVouchers?type=total&status=redeemed`;
    if(redemption_dates=='true'){
      url+='&redemption_dates=true';
    }

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  /*
  getRedeemedProductCoupons() {
    let url = `/ebsn/api/coupon/getVouchers?type=product&status=redeemed`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  */
  getRedeemedDonations() {
    let url = `/ebsn/api/coupon/getRedeemedDonations`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getCashbackBar() {
    let url = `/ebsn/api/coupon/getCashbackBar`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getCashbackCalendar(dataIni,dataFin) {
    let url = `/ebsn/api/coupon/getCashbackCalendar?dataIni=${dataIni}&dataFin=${dataFin}`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getActivatedDiscountsByType(type) {
    let url = `/ebsn/api/coupon/getActivatedDiscounts?type=${type}`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getIncomingDiscounts(type) {
    let url = `/ebsn/api/coupon/getIncomingDiscounts?type=${type}`;
    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getRedeemedDiscountsByType(type) {
    let url = `/ebsn/api/coupon/getRedeemedDiscounts?type=${type}`;

    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
  getAdventCalendar() {
    let url = `/ebsn/api/coupon/getAdventCalendar`;
    return axios.get(url).then(data => {
      return data.data.data;
    });
  },
};
