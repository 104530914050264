<template>
  <!-- FIDELTY CARD NUMBERE -->
  <v-form class="d-flex flex-wrap" ref="form" @submit.prevent v-model="isValid">
    <v-row no-gutters>
      <!-- CARTA FEDELTA -->
      <v-col cols="12" md="5" v-if="isRegisterWithCard" class="mb-2">
        <v-text-field
          :label="
            `${$t('register.card.step-0.placeholder.codiceCartaFedeltà')} *`
          "
          :hint="$t('register.card.step-0.placeholder.ccfHint')"
          persistent-hint
          :rules="[requiredRules()]"
          class="mt-2"
          required
          outlined
          rounded
          dense
          type="number"
          :disabled="isFieldDisabled"
          v-model="userData.fidelity"
          append-icon="$barcodeScan"
          @click:append="scanCard"
          :loading="loadingCard"
        />
      </v-col>
      <!-- NOME DEL UTENTE -->
      <v-col cols="12" md="6" v-if="askFirstName">
        <v-text-field
          :label="`${$t('register.card.step-0.placeholder.name')} *`"
          :rules="[requiredRules()]"
          class="mt-2"
          required
          outlined
          rounded
          dense
          type="text"
          v-model="userData.firstName"
          :disabled="isFieldDisabled"
        />
      </v-col>
      <!-- COGNOME DEL UTENTE-->
      <v-col cols="12" md="5" v-if="askLastName">
        <v-text-field
          :label="`${$t('register.card.step-0.placeholder.surname')} *`"
          :rules="[requiredRules()]"
          class="mt-2"
          required
          outlined
          rounded
          dense
          type="text"
          v-model="userData.lastName"
          :disabled="isFieldDisabled"
        />
      </v-col>
      <div
        class="w-100 d-flex flex-column flex-md-row justify-sm-space-between justify-md-start"
        v-if="showButtons"
      >
        <v-col md="5" class="px-0">
          <v-btn
            depressed
            color="primary"
            block
            rounded
            large
            :loading="loading"
            @click="submit"
            >{{ $t("register.card.step-0.btnConfirm") }}</v-btn
          ></v-col
        >
      </div>
    </v-row>
  </v-form>
</template>

<script>
import { requiredValue } from "~/validator/validationRules";
import { getPersonInfo } from "~/service/ebsn";

export default {
  name: "UserDataForm",
  props: {
    userData: {
      type: Object,
      required: true
    },
    isFieldDisabled: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    isProfileForm: { type: Boolean, default: false },
    isRegisterWithCard: { type: Boolean, default: false },
    askFirstName: { type: Boolean, default: true },
    askLastName: { type: Boolean, default: true },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      isValid: false,
      menu: false,
      activePicker: null,
      requiredRules: requiredValue,
      loadingCard: false,
      response: {}
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
      }
    },
    sign() {
      return getPersonInfo(this.userData.person, 5);
    }
  },
  methods: {
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate");
          }
        }
      }, 200);
    },
    scanCard() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loadingCard = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loadingCard = false;
                const barcode = result.text.length === 12 ? '0' + result.text : result.text;
                this.$set(this.userData, "fidelity", barcode);
              }
            },
            error => {
              this.loadingCard = false;
              console.error("Scanning failed", error);
              if (
                error
                  .toString()
                  .toLowerCase()
                  .includes("illegal access")
              ) {
                this.$dialog.confirm({
                  text: `<p class='text-center font-weight-semibold pre-wrap py-3'>${this.$t(
                    "message.cameraPermission"
                  )}</p>`,
                  class: "",
                  actions: {}
                });
              }
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500,
              formats:
                this.formats || "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR"
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    submit() {
      if (this.isValid) {
        this.$emit("submit", this.userData);
      }
    }
  },
  created() {}
};
</script>
