import Vue from "vue";
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
  VTextarea,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VDatePicker,
  VColorPicker,
  VFileInput
} from "vuetify/lib";
import i18n from "./i18n";
import "@/scss/_fonts.scss";
import { Ripple, Intersect, Touch, Resize } from "vuetify/lib/directives";
import VFormText from "@/components/forms/FormText.vue";
import VFormFileViewer from "@/components/forms/FormFileViewer.vue";

Vue.use(Vuetify, {
  components: {
    VRow,
    VTooltip,
    VCol,
    VTextField,
    VCheckbox,
    VSelect,
    VTextarea,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VDatePicker,
    VColorPicker,
    VFileInput,
    VFormText,
    VFormFileViewer
  },
  directives: { Ripple, Intersect, Touch, Resize }
});

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        logo: {
          default: "/logo/logo.png",
          mobile: "/logo/logo.png"
        },
        default: {
          base: "#303030",
          lighten1: "#534741"
        },
        primary: {
          base: "#4C9D2A",
          lighten1: "#D5EBD0",
          lighten2: "#ffd1b4",
          darken1: "#c95a13",
          darken2: "#8a3e0d"
        },
        secondary: {
          base: "#d0112b",
          lighten1: "#044898",
          lighten2: "#2c81e5",
          darken1: "#8C0723",
          darken2: "#001227",
          darken3: "#022348"
        },
        accent: {
          base: "#284A50"
        },
        white: "#FFFFFF",
        grey: {
          base: "#D2D2D2",
          lighten1: "#F3F4F7",
          lighten2: "#DFDDDE",
          lighten3: "#E1E1E1",
          darken1: "#8D8D8D"
        },
        warning: "#ff8484",
        customYellow: "#feee00"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: "icon",
    values: {
      cookiePolicy: "icon-cookie-policy",
      download: "icon-download1",
      done: "icon-done",
      copy: "icon-copy",
      myLocation: "icon-my_location",
      map: "icon-world",
      schedule: "icon-calendar",
      call: "icon-callXX",
      arrowBack: "icon-arrow-left",
      arrowDownward: "icon-arrow_down",
      arrowForward: "icon-arrow-right",
      calendar: "icon-scadenza",
      event: "icon-event",
      fastSearch: "icon-search",
      location: "icon-location",
      arrowTopLeft: "icon-arrow_up",
      buoni: "icon-buoni",
      cart: "icon-cart",
      chevronDown: "icon-chevron_down",
      chevronLeft: "icon-chevron_left",
      chevronRight: "icon-chevron_right",
      chevronUp: "icon-chevron_up",
      creditCard: "icon-credit_card",
      eye: "icon-eye",
      eyeOff: "icon-eye_off",
      filter: "icon-filter_list",
      heart: "icon-favorite",
      heartfull: "icon-favorite_full",
      home: "icon-home",
      indirizzi: "icon-address",
      minus: "icon-minus",
      plus: "icon-plus",
      profile: "icon-profilo",
      profileFull: "icon-user_black",
      search: "icon-ricerca",
      settings: "icon-settings",
      star: "icon-utilizza-punti",
      starFull: "icon-star20",
      twitter: "icon-twitterXXX",
      youtube: "icon-youtubeXXX",
      instagram: "icon-instagramXXX",
      facebook: "icon-facebookXXX",
      linkedin: "icon-linkedinDDD",
      coupon: "icon-coupon",
      volantino: "icon-volantino",
      campanella: "icon-campanella",
      premi: "icon-storico-premi",
      listaSpesa: "icon-lista-spesa",
      preferiti: "icon-preferiti",
      ottieniPunti: "icon-ottieni-punti",
      regalaPunti: "icon-regala-punti",
      lotteriaScotrini: "icon-lotteria-scotrini",
      sostenibilita: "icon-gala-sostenibilita",
      giftCard: "icon-carta-regala",
      magazine: "icon-magazine",
      richiamoProdotti: "icon-richiamo-prodotti",
      parlaConNoi: "icon-parla-con-noi",
      informazioniLegali: "icon-informazioni-legali",
      whoWeAre: "icon-chi-siamo",
      qrCode: "icon-qr_code_scanner",
      galaCard: "icon-carta-gala",
      fullscreen: "icon-fullscreen-volantino",
      zoom: "icon-magnifying-glass-plus-light",
      zoomOut: "icon-magnifying-glass-minus-light",
      share: "icon-condividi",
      difficulty: "icon-ricette-difficolt",
      servings: "icon-ricette-porzioni",
      prepTime: "icon-ricette-preparazione",
      cookingTime: "icon-ricette-cottura",
      invoice: "icon-invoice",
      privacy: "icon-privacy",
      //icone standard vuetify da codificare sempre
      complete: "icon-completeXXX",
      cancel: "icon-close",
      close: "icon-close",
      delete: "icon-trash-can-light",
      clear: "icon-close",
      success: "icon-complete",
      info: "icon-info1",
      warning: "icon-warning",
      error: "icon-error",
      prev: "icon-chevron_left",
      next: "icon-chevron_right",
      checkboxOff: " icon-check_box_outline_blank",
      checkboxOn: "icon-check_box",
      checkboxIndeterminate: "...",
      delimiter: "...", // for carousel
      sort: "...",
      expand: "icon-chevron_down",
      menu: "icon-menu",
      subgroup: "...",
      dropdown: "icon-chevron_down",
      radioOn: "icon-radio_button_checked",
      radioOff: "icon-radio_button_unchecked",
      edit: "icon-edit",
      print: "icon-print",
      ratingEmpty: "icon-star_outline",
      ratingFull: "icon-star_full",
      ratingHalf: "icon-star_half",
      loading: "icon-cached",
      first: "icon-first_page",
      last: "icon-last_page",
      unfold: "icon-unfold",
      file: "icon-file",
      more_vert: "icon-more_vert",
      barcode: "icon-barcode",
      barcodeScan: "icon-barcode-scan",
      tag: "icon-tag",
      percent: "icon-percent",
      logout: "icon-esci",
      bell: "icon-notifications",
      recipe: "icon-recipe",
      key: "icon-key-light"
    }
  }
});
