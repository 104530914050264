import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import get from "lodash/get";

const UPDATE_PRICE_DISPLAY_DISCLAIMER = "updatePriceDisplayDisclaimer";
const UPDATE_SHOW_WELCOME_PAGE = "updateShowWelcomePage";
const UPDATE_USER_POINTS = "updateUserPoints";

const ADD_SHOPPING_ITEM = "addShopingItem";
const REMOVE_SHOPPING_ITEM = "removeShopingItem";
const CLEAR_SHOPPING_LIST = "clearShoppingList";
const TOGGLE_ITEM_SELECTION = "toggleItemSelection";

const ADD_ARTICLE = "addArticle";
const REMOVE_ARTICLE = "removeArticle";

const SELECT_STORE = "selectStore";
const REMOVE_STORE = "removeStore";

const ADD_LOTTERY_CODE = "addLotteryCode";
const REMOVE_LOTTERY_CODE = "removeLotteryCode";

const SET_INITIAL_DATA = "setInitialData";

const state = {
  priceDisplayDisclaimer: true,
  showWelcomePage: true,
  userPoints: { value: { punti: "0" } },
  shoppingList: {},
  savedArticles: {},
  receiptLotteryCode: {},
  selectedStoreNoAccount: null
};
const getters = {
  userPoints: state => {
    return state.userPoints.value.punti;
  },
  getShoppingList: state => id => {
    if (!state.shoppingList[id]) {
      state.shoppingList[id] = [];
    }
    return state.shoppingList[id];
  },
  getShoppingListAll: state => {
    return state.shoppingList;
  },
  getSavedArticles: state => userId => {
    if (state.savedArticles[userId]) {
      return state.savedArticles[userId];
    } else return [];
  },
  isArticleSaved: state => data => {
    if (state.savedArticles && state.savedArticles[data.userId]) {
      return state.savedArticles[data.userId].some(
        article => article === data.articleId
      );
    } else return false;
  },
  getLotteryCodeByUser: state => id => {
    return state.receiptLotteryCode[id];
  },
  getSelectedStore: state => {
    return state.selectedStoreNoAccount;
  }
};

const actions = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]({ commit }, value) {
    commit(UPDATE_PRICE_DISPLAY_DISCLAIMER, value);
  },
  [UPDATE_SHOW_WELCOME_PAGE]({ commit }, value) {
    commit(UPDATE_SHOW_WELCOME_PAGE, value);
  },
  async [UPDATE_USER_POINTS]({ commit }, wallet) {
    const value = await AbbondanzaRegistrationService.getPoints(wallet);
    commit(UPDATE_USER_POINTS, value);
  },
  [SELECT_STORE]({ commit }, value) {
    commit(SELECT_STORE, value);
  },
  [REMOVE_STORE]({ commit, rootState }) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(REMOVE_STORE, id);
  },
  addShoppingItems({ commit, rootState }, values) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    values.forEach(value => {
      commit(ADD_SHOPPING_ITEM, { value: value, id: id });
    });
  },
  addShoppingItem({ commit, rootState }, value) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(ADD_SHOPPING_ITEM, { value: value, id: id });
  },
  removeShoppingItem({ commit, rootState }, value) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(REMOVE_SHOPPING_ITEM, { value: value, id: id });
  },
  clearShoppingList({ commit, rootState }) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(CLEAR_SHOPPING_LIST, id);
  },
  toggleItemSelection({ commit, rootState }, itemIndex) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(TOGGLE_ITEM_SELECTION, { index: itemIndex, id: id });
  },
  toggleArticle({ commit, state, rootState }, value) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    if (!state.savedArticles) {
      state.savedArticles = {};
    }
    if (!state.savedArticles[id]) {
      state.savedArticles[id] = [];
    }
    const articleFound = state.savedArticles[id].indexOf(value);
    if (articleFound !== -1) {
      commit(REMOVE_ARTICLE, { index: articleFound, id: id });
    } else {
      commit(ADD_ARTICLE, { value: value, id: id });
    }
  },
  addLotteryCode({ commit, rootState }, value) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(ADD_LOTTERY_CODE, { value: value, id: id });
  },
  removeLotteryCode({ commit, rootState }) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(REMOVE_LOTTERY_CODE, id);
  },
  setInitialData({ commit, rootState }) {
    let id = get(rootState, "cart.cart.user.userId", "noAccount");
    commit(SET_INITIAL_DATA, id);
  }
};
const mutations = {
  [UPDATE_PRICE_DISPLAY_DISCLAIMER]: (state, value) => {
    state.priceDisplayDisclaimer = value;
  },
  [UPDATE_SHOW_WELCOME_PAGE]: (state, value) => {
    state.showWelcomePage = value;
  },
  [UPDATE_USER_POINTS]: (state, value) => {
    state.userPoints = value;
  },
  [SELECT_STORE]: (state, value) => {
    state.selectedStoreNoAccount = value;
  },
  [REMOVE_STORE]: () => {
    state.selectedStoreNoAccount = null;
  },
  [ADD_SHOPPING_ITEM]: (state, value) => {
    if (!state.shoppingList) {
      state.shoppingList = {};
    }
    if (!state.shoppingList[value.id]) {
      state.shoppingList[value.id] = [];
    }
    state.shoppingList[value.id].push(value.value);
  },
  [REMOVE_SHOPPING_ITEM]: (state, value) => {
    if (state.shoppingList && state.shoppingList[value.id]) {
      state.shoppingList[value.id].splice(value.value, 1);
    }
  },
  [TOGGLE_ITEM_SELECTION]: (state, value) => {
    if (state.shoppingList && state.shoppingList[value.id]) {
      state.shoppingList[value.id][value.index].selected = !state.shoppingList[
        value.id
      ][value.index].selected;
    }
  },
  [CLEAR_SHOPPING_LIST]: (state, value) => {
    state.shoppingList[value] = [];
  },
  [ADD_ARTICLE]: (state, value) => {
    state.savedArticles[value.id].push(value.value);
  },
  [REMOVE_ARTICLE]: (state, value) => {
    state.savedArticles[value.id].splice(value.index, 1);
  },
  [ADD_LOTTERY_CODE]: (state, value) => {
    if (!state.receiptLotteryCode) {
      state.receiptLotteryCode = {};
    }
    state.receiptLotteryCode[value.id] = value.value;
  },
  [REMOVE_LOTTERY_CODE]: (state, id) => {
    state.receiptLotteryCode[id] = null;
  },
  [SET_INITIAL_DATA]: (state, id) => {
    if (!state.shoppingList[id]) {
      state.shoppingList[id] = [];
    }
    if (!state.savedArticles[id]) {
      state.savedArticles[id] = [];
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
