<template>
  <div>
    <v-form v-model="isValid" ref="form" @submit.prevent class="privacy-form">
      <v-row no-gutters class="px-2">
        <v-container>
          <v-col cols="12" class="privacy-container">
            <div class="font-weight-semibold">
              {{ $t("profile.disclaimerPrivacyTitle") }}
            </div>
            <div>
              <v-checkbox
                v-model="checked"
                :rules="checkboxRules"
                class="disclaimer-checkbox"
              >
                <template v-slot:label>
                  <i18n
                    path="profile.disclaimerMainReduced2"
                    tag="span"
                    style="color: black;"
                    class="pre-wrap mt-2 text-body-2"
                  >
                    <template v-slot:link1>
                      <span
                        class="text-decoration-underline font-weight-semibold"
                        style="font-size: 13px;"
                        @click.prevent.stop="
                          handleRedirect(
                            'https://www.galasupermercati.it/privacy-note-legali'
                          )
                        "
                        >{{ $t("profile.disclaimerMainReduced2Link") }}</span
                      >
                    </template>
                  </i18n>
                </template>
              </v-checkbox>
              <div>
                {{ $t("profile.marketingDisclaimer") }}
              </div>
              <div class="d-flex justify-space-around">
                <v-radio-group :rules="[requiredRules()]" class="inline-radio" row v-model="userData.marketing">
                  <v-radio class="pr-10" label="Si" value="1"></v-radio>
                  <v-radio class="pl-10" label="No" value="0"></v-radio>
                </v-radio-group>
              </div>
              <div v-if="userData.marketing=='0'" class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("profile.privacyMarketing.alert") }}
                  </div>
                </div>
              </div>
          <div v-if="userData.marketing === '1'">
            
            <div class="font-weight-semibold">
              {{ $t("profile.contactChannels") }}
            </div>
            <div>
              {{ $t("profile.contactChannelsDesc") }}
            </div>
            <!-- <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 text-body-0 pr-3">
                  {{ $t("profile.emailNewsletter") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                  ></v-switch>
                </div>
              </div>
            </div> -->
            <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 pr-3">
                  {{ $t("profile.emailPromo") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                    v-model="userData.canaleMail"
                    false-value="0"
                    true-value="1"
                  ></v-switch>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 pr-3">
                  {{ $t("profile.sms") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                    v-model="userData.canaleSms"
                    false-value="0"
                    true-value="1"
                  ></v-switch>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-center justify-center my-2">
                <div class="flex-grow-1 pr-3">
                  {{ $t("profile.notifications") }}
                </div>
                <div class="ios-style-switch mr-2">
                  <v-switch
                    inset
                    color="primary"
                    hide-details
                    :ripple="false"
                    v-model="userData.canalePush"
                    false-value="0"
                    true-value="1"
                  ></v-switch>

                </div>
              </div>
            </div>
          </div>

              <div>
                <br>
                {{ $t("profile.marketingDisclaimer2") }}
              </div>
              <div class="d-flex justify-space-around">
                <v-radio-group :rules="[requiredRules()]" class="inline-radio" row v-model="userData.profilazione">
                  <v-radio class="pr-10" label="Si" value="1"></v-radio>
                  <v-radio class="pl-10" label="No" value="0"></v-radio>
                </v-radio-group>
              </div>
              <div v-if="userData.profilazione == '0'" class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("profile.privacyProfiling.alert") }}
                   </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-container>
      </v-row>
    </v-form>
  </div>
</template>
<style lang="scss">
.privacy-form {
  .disclaimer-label {
    color: var(--v-default-base);
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
<script>
import { requiredValue } from "~/validator/validationRules";
import { isCheckboxTrueBool } from "@/customValidationRules";

export default {
  name: "PrivacySection",
  props: {
    userData: { type: Object, required: true }
  },
  data() {
    return {
      isValid: false,
      checked:false,
      response: {},
      requiredRules: requiredValue,
      checkboxRules: [
        isCheckboxTrueBool(
          "È obbligatorio accettare questo consenso per procedere"
        )
      ],
    };
  },
  mounted(){
    if(this.userData.hasCard==true){
      this.checked=true;
    }else{
      this.userData.canaleSms="1";
      this.userData.canaleMail="1";
      this.userData.canalePush="1";
    }
  },
  methods: {
    handleRedirect(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
  }
};
</script>
