<template>
  <div class="advent-calendar">
          <!-- Overlay for the GIF -->

    <ResponseMessage :response="response" title="OPS!" class="mt-3" />

    <v-overlay v-model="showOverlay" opacity="0.8">
      <img class="overlay-img"
        src="/img_layout/advent_calendar/paccho-open.gif"
      />
    </v-overlay>
    <div class="header">
      <img
          src="/img_layout/advent_calendar/logo.png"
          style="width: 70%;"
        />
    </div>
    <div class="calendar-grid">
      <div
        v-for="(day, index) in localCalendar"
        @click="clickCoupon(day)"
        :key="index"
        class="calendar-day"
        :class="{
        'calendar-day-open': day.status === 'Redeemed' || day.status === 'Booked' || day.status === 'Expired'}"
        :style="getBackgroundImage(day)"
      >
      <span class="day-number"
      :class="{
        'package-opened': day.status === 'Redeemed' || day.status === 'Booked' || day.status === 'Expired',
        'day-number-disabled': day.status === 'Redeemed' || day.status === 'Expired' || day.status === 'Missed',
        'day-number-enabled': !(day.status === 'Redeemed' || day.status === 'Expired' || day.status === 'Missed')
      }">
  {{ day.day }}
</span>
      </div>
    </div>
  </div>
</template>

<script>
import couponsService from '@/service/couponsService';
import ActiveCouponDetail from '../coupon/ActiveCouponDetail.vue';
import ResponseMessage from '../ResponseMessage.vue';
export default {
  name: "AdventCalendarBlock",
  components: {ResponseMessage},
  data() {
    return {
      showOverlay:false,
      localCalendar: [...this.calendar!=null?this.calendar:[]],  // Create a local copy of calendar
      response: {},
    };
  },
  props: {
    calendar: { type: [Array,null], required: true },
    availableCoupon: { type: Object, required: false },
  },
  watch:{
    availableCoupon(newVal, oldVal) {
      console.log(oldVal);
    if(newVal != null && newVal != undefined){
      setTimeout(()=>{
        this.clickCoupon(newVal);
      },3000)
      }
    },
    calendar(newVal,oldVal){
      console.log(oldVal);
      this.localCalendar=newVal;
    }
  },
  methods: {
    formatDate(dateString) {
      // Extract year, month, and day from the input date string (yyyymmdd)
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6);
      const day = dateString.slice(6, 8);
      
      // Return the date in "dd/mm/yyyy" format
      return `${day}/${month}/${year}`;
    },
    async clickCoupon(day){
      switch(day.status){
       case "Available":
          try{
            let temp = this.localCalendar.find(item => item.product.id === day.product.id);
            temp.status = "Waiting";
            let res = await couponsService.activateDiscount(day.product.id,"calendar");
            day.barCode = res.data.value.response.dinamiche.dinamica[0].buoni.lstBuoni.buono[0].upc;
            temp.endRetreat = this.formatDate(res.data.value.response.dinamiche.dinamica[0].buoni.lstBuoni.buono[0].dataFineRitiro);
            temp.barCode = day.barCode;
            temp.status = "Booked";
            await this.showGifOverlay();
            this.showActiveCoupon(day,"activeCashbackDiscount");
          }catch(e){
            console.log(e);
            let temp = this.localCalendar.find(item => item.product.id === day.product.id);
            temp.status = "Available";
          }
          break;
        case "Booked":
          await this.showActiveCoupon(day,"activeCashbackDiscount");
          break;
        case "Missed":
          this.response = {"errors":[{"error":"Il buono non è più disponibile. Non perderti il regalo di oggi!"}]};
          break;
        case"Expired":
          this.response = {"errors":[{"error":"Il buono non è più disponibile. Non perderti il regalo di oggi!"}]};
          break;
        case 'Upcoming':{
          const msg = "Torna il "  + day.day + " Dicembre per attivare il buono sconto!"
          this.response = {"errors":[{"error":msg}]};
          break;
        }
        case 'Redeemed':
          this.response = {"errors":[{"error":"Buono sconto già utilizzato!"}]};
          break;
      }
    },
    async showActiveCoupon(coupon, type) {
      let res = await this.$dialog.show(ActiveCouponDetail, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        coupon: coupon,
        type: type,
        width: 500
      });
      if (res) {
        console.log(res);
      }
    },
    async showGifOverlay() {
      this.showOverlay = true;
      // Return a promise that resolves after 3 seconds
      return new Promise(resolve => {
        setTimeout(() => {
          this.showOverlay = false;
          resolve();
        }, 2500);
      });
    },

    unlockDay(index) {
      if (this.days[index].locked) {
        this.$set(this.days, index, { ...this.days[index], locked: false });
      }
    },
    howItWorks() {
      alert("Ecco come funziona il calendario!");
    },
    getBackgroundImage(day) {
      // Set different background image depending on the lock status
      return {
        backgroundImage: (() => {
          switch (day.status) {
            case "Missed":
              return "url('/img_layout/advent_calendar/paccho-missed.svg')"; // locked background image
            case 'Booked':
              return "url('/img_layout/advent_calendar/paccho-booked.svg')"; // unlocked background image
            case 'Expired':
              return "url('/img_layout/advent_calendar/paccho-used.svg')"; // optional default case
            case 'Available':
              return "url('/img_layout/advent_calendar/paccho-available.svg')"; // optional default case
            case 'Redeemed':
              return "url('/img_layout/advent_calendar/paccho-used.svg')"; // optional default case
            default:
              return "url('/img_layout/advent_calendar/paccho-available.svg')"; // optional default case
         }
        })(),
      };
    }
  },
};
</script>

<style scoped>

.overlay-img{
  width: 100%;
}

.advent-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h1 {
  margin-bottom: 20px;
}

.calendar-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 5px; /* Optional spacing between cells */
  background-image: url('/img_layout/advent_calendar/bg-lucine.svg');
  background-repeat: repeat;
}

.calendar-day {
  width: 85%;
  margin-top: 22px;
  height: 74px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns text content to the bottom */
  align-items: center;       /* Centers content horizontally */
  background-size: contain;  /* Keeps image within cell without cropping */
  background-position: center;
  background-repeat: no-repeat; /* Ensures no tiling of background */
}
.calendar-day-open {
  margin-top: 0px;
  height: 96px;
  }

.day-number {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 900;
  font-size: 25px;
  color: #d4213d
;  
  margin-bottom: 17px; /* Adds a bit of space above the text */
  line-height: 1.1 !important;
}

.day-number-disabled {
  color: #43454b;
}
.package-opened{
  margin-left: 5px;
}
.header{
  text-align: center;
}
</style>
