import { render, staticRenderFns } from "./ProposalCardHorizontal.vue?vue&type=template&id=344cf85e"
import script from "./ProposalCardHorizontal.vue?vue&type=script&lang=js"
export * from "./ProposalCardHorizontal.vue?vue&type=script&lang=js"
import style0 from "./ProposalCardHorizontal.vue?vue&type=style&index=0&id=344cf85e&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('344cf85e')) {
      api.createRecord('344cf85e', component.options)
    } else {
      api.reload('344cf85e', component.options)
    }
    module.hot.accept("./ProposalCardHorizontal.vue?vue&type=template&id=344cf85e", function () {
      api.rerender('344cf85e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/categoryBlock/elements/ProposalCardHorizontal.vue"
export default component.exports