import { render, staticRenderFns } from "./DiscountsSlider.vue?vue&type=template&id=19fd2173"
import script from "./DiscountsSlider.vue?vue&type=script&lang=js"
export * from "./DiscountsSlider.vue?vue&type=script&lang=js"
import style0 from "./DiscountsSlider.vue?vue&type=style&index=0&id=19fd2173&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19fd2173')) {
      api.createRecord('19fd2173', component.options)
    } else {
      api.reload('19fd2173', component.options)
    }
    module.hot.accept("./DiscountsSlider.vue?vue&type=template&id=19fd2173", function () {
      api.rerender('19fd2173', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/categoryBlock/DiscountsSlider.vue"
export default component.exports