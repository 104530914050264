<template>
  <v-card
    class="product-card"
    :class="type"
    :id="`product-card-${product.giftCertificateId}`"
    ref="productCard"
    :key="componentKey"
    elevation="2"
    @click="openDetails"
  >
    <div class="top-part">
      <div class="corner-icon-wrap">
        <img src="/img_layout/thumbs-up-light.svg" alt="thumbs-up" />
      </div>
      <div class="d-flex">
        <img
          :src="product.categoryProposal.img"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img"
          :alt="product.categoryProposal.imgAlt"
          :title="product.categoryProposal.imgAlt"
        />
      </div>
      <div class="product-detis">
        <div class="product-title">{{ product.name }}</div>
        <div class="type">Sconto</div>
        <div class="value">
          {{ amount }} <span>{{ discountType }}</span>
        </div>
      </div>
      <div class="info-icons">
        <div>
          <img
            v-if="expiring"
            src="/img_layout/clessidra.svg"
            alt="clessidra"
          />
        </div>
        <div class="amount">{{ minUnits }}<span>Pz</span></div>
      </div>
    </div>
    <div class="bottom-part">
      <div class="product-info">
        <div>
          <v-icon color="white">$coupon</v-icon
          >{{
            $t("coupons.galery.available", {
              number: availableRedemptions
            })
          }}
        </div>
        <div><v-icon color="white">$calendar</v-icon>{{ remainingDays }}</div>
      </div>
      <div class="action">
        <v-btn
          rounded
          block
          color="white"
          large
          elevation="0"
          @click.stop.prevent="toggleCoupon"
          >{{
            type == "available"
              ? $t("coupons.galery.add")
              : $t("coupons.galery.remove")
          }}</v-btn
        >
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  border-radius: 8px !important;
  height: 180px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top-part {
    display: flex;
    column-gap: 8px;
    height: 65%;
    overflow: hidden;
    padding: 10px;
    .corner-icon-wrap {
      position: absolute;
      height: 40px;
      width: 40px;
      top: 0;
      left: 0;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      img {
        padding: 4px;
      }
    }
    .product-img {
      width: 110px;
      align-self: center;
      flex: 0 0 auto;
    }
    .product-detis {
      flex-grow: 1;
      .product-title {
        font-size: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .type {
        font-size: 20px;
        font-weight: bolder;
        color: var(--v-secondary-darken1);
        text-transform: uppercase;
      }
      .value {
        font-size: 32px;
        line-height: 32px;
        font-weight: bolder;
        color: var(--v-secondary-darken1);
        span {
          font-size: 75%;
        }
      }
    }
    .info-icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .amount {
        color: var(--v-secondary-darken1);
        padding: 5px;
        font-weight: bold;
        font-size: 24px;
        span {
          font-size: 70%;
        }
      }
    }
  }
  .bottom-part {
    width: 100%;
    flex-grow: 1;
    padding: 5px 10px 5px 10px;
    color: $white;
    display: flex;
    align-items: center;
    .product-info {
      flex-grow: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      div {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      .v-icon {
        font-weight: normal;
        font-size: 16px;
        margin-right: 5px;
        width: 20px;
      }
    }
    .action {
      width: 45%;
    }
  }
}
.product-card.available {
  .top-part {
    .corner-icon-wrap {
      display: none;
    }
  }
  .bottom-part {
    background-color: var(--v-secondary-base);
    .action {
      .v-btn__content {
        color: var(--v-secondary-base);
      }
    }
  }
}
.product-card.selected {
  .top-part {
    .corner-icon-wrap {
      background-color: var(--v-primary-base);
    }
  }
  .bottom-part {
    background-color: var(--v-primary-base);
    .action {
      .v-btn__content {
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
<script>
import productMixin from "~/mixins/product";
import dayjs from "dayjs";
import get from "lodash/get";

import { mapState, mapGetters } from "vuex";
import CouponsService from "@/service/couponsService";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    type: { type: String, default: "available" }
  },
  directives: {},
  components: {},
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    // item() {
    //   this.key;
    //   let item = this.getItem({
    //     product: this.product,
    //     ...this.selectedOptions
    //   });
    //   return item;
    // },
    item() {
      return null;
    },
    remainingDays() {
      let targetDate = dayjs(this.product.endDate);
      let today = dayjs().startOf("day"); // Get the current date

      let daysLeft = targetDate.diff(today, "day");
      if (daysLeft == 0) {
        return this.$t("coupons.galery.expiresToday");
      }
      return this.$t("coupons.galery.expires", {
        number: daysLeft
      });
    },
    availableRedemptions() {
      return get(this.product, "metaData.voucher_info.available_redemptions");
    },
    minUnits() {
      return get(this.product, "metaData.voucher_info.min_units", 1);
    },
    amount() {
      return get(this.product, "userGiftCertificates[0].amount");
    },
    expiring() {
      return get(this.product, "metaData.voucher_info.expiring");
    },
    discountType() {
      let type = get(this.product, "giftAdjustmentType.descr");
      switch (type) {
        case "PercentOff":
          return "%";
        case "FixedAmounOff":
          return "€";
        default:
          return "";
      }
    }
  },
  methods: {
    openDetails() {
      this.product.type = (this.product.userGiftCertificates[0].giftCertificateStatus.giftCertificateStatusId == 1)?"available":"selected";
      this.$emit("openDetails", this.product);
    },
    async toggleCoupon() {
;     console.log(this.$route)
      if (this.type == "available") {
        await CouponsService.activateGaleryCoupon(this.product.giftCode);
        this.product.userGiftCertificates[0].giftCertificateStatus.giftCertificateStatusId = 2;
      } else {
        await CouponsService.deactivateGaleryCoupon(this.product.giftCode);
        this.product.userGiftCertificates[0].giftCertificateStatus.giftCertificateStatusId = 1;
      }
      if(this.$route.name == 'PersonalDiscounts'){
        this.$emit("reload");
      }
    }
  },
  mounted() {}
};
</script>
