import { render, staticRenderFns } from "./NinjaFormsListRadio.vue?vue&type=template&id=06c10712"
import script from "./NinjaFormsListRadio.vue?vue&type=script&lang=js"
export * from "./NinjaFormsListRadio.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06c10712')) {
      api.createRecord('06c10712', component.options)
    } else {
      api.reload('06c10712', component.options)
    }
    module.hot.accept("./NinjaFormsListRadio.vue?vue&type=template&id=06c10712", function () {
      api.rerender('06c10712', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/commons/components/ninjaForms/NinjaFormsListRadio.vue"
export default component.exports