<template>
  <v-container fluid class="registration-user-container pb-0 pt-12 px-0">
    <div
      class="d-flex flex-column align-center justify-space-between w-100 pa-3"
    >
      <img src="/logo/logo.png" alt="Logo" :style="{ height: '70px' }" />
      <h2
        class="default--text"
        v-html="$t('register.user.title')"
        v-if="step == 0"
      ></h2>
    </div>
    <div class="pl-2 step-container" v-if="step > 0 && step <= totalStep">
      <div class="step-text">
        {{ $t("register.step") }} {{ step }} {{ $t("register.of") }}
        {{ totalStep }}
      </div>
      <v-progress-linear
        color="primary"
        class="progressBarSteps"
        model-value="100"
        v-bind:value="(step / totalStep) * 100"
      ></v-progress-linear>
    </div>
    <div class="pl-2" v-if="step == 1">
      <h2 style="color: black; margin: 8px 0 0 0;">
        {{ $t("register.user.signIn") }}
      </h2>
      <div>{{ $t("register.user.signInTextStep1") }}</div>
    </div>
    <div
      class="d-flex justify-center align-center flex-column w-100 grey lighten-1 pa-5 text-center"
      v-if="step == 0"
    >
      <h4 class="default--text px-2">
        {{ $t("register.user.alreadyCustomer") }}?
      </h4>
      <v-btn-toggle
        v-model="hasCard"
        tile
        color="primary"
        group
        @change="resetUserData"
      >
        <v-btn :value="true" outlined>
          {{ $t("common.yes") }}
        </v-btn>

        <v-btn :value="false" outlined>
          {{ $t("common.no") }}
        </v-btn>
      </v-btn-toggle>
      <div
        class="default--text mt-2 text-decoration-underline"
        @click="goToPage('/privacy-policy')"
      >
        Privacy Policy
      </div>
    </div>
    <div v-if="hasCard != null">
      <div v-if="hasCard == true && step == 0" class="pa-2">
        <div>
          <!-- <UserEmailAddress
            ref="userEmailAddressDataForm"
            :userData.sync="userData"
            :hasCard="hasCard"
            :isFieldDisabled="hasCard == null"
          /> -->
          <UserData
            ref="userDataForm"
            :userData.sync="userData"
            :hasCard="hasCard"
            :isFieldDisabled="hasCard == null"
            :isRegisterWithCard="true"
            :loading="chekingCard"
            @submit="checkUserCardData"
          />
        </div>
      </div>
      <div v-else>
        <NewCustomer
          ref="newCustomerDataForm"
          :userData.sync="userData"
          :step="step"
          :hasCard="hasCard"
          :isFieldDisabled="hasCard == null"
        />
        <PrivacySection
          v-if="step == 3"
          :userData.sync="privacyData"
          ref="newCustomerPrivacyForm"
        />
      </div>
      <ResponseMessage :response="response" class="mt-4 pa-2" />
      <div
        class="pa-2"
        v-if="
          response.errorsMessage &&
            response.errorsMessage[0].code ==
              'error.registration.already.registered'
        "
      >
        <v-btn
          @click="login"
          depressed
          block
          rounded
          large
          outlined
          color="primary"
          class="mt-3"
          >{{ $t("login.buttonLogin") }}</v-btn
        >
        <v-btn
          @click="openResetPasswordDialog"
          depressed
          block
          rounded
          large
          outlined
          color="primary"
          class="mt-3"
          >{{ $t("login.recoverPassword") }}</v-btn
        >
      </div>
    </div>
    <div class="d-flex justify-center pa-5" v-else>
      <img src="/img_layout/carta-gala-2.png" class="card-img" />
    </div>
    <v-row class="pl-2 pr-2 button-container" v-if="step > 0">
      <v-col cols="6" class="btn-container">
        <v-btn
          v-if="step <= totalStep"
          @click="goBack"
          depressed
          block
          rounded
          large
          text
          class="col-6 btn-back font-weight-semibold"
          ><v-icon class="pr-2" x-small>$arrowBack</v-icon
          >{{ $t("register.user.goBack") }}</v-btn
        >
      </v-col>
      <v-col cols="6">
        <v-btn
          v-if="step < totalStep"
          @click="goForward"
          rounded
          block
          large
          color="primary"
          class="col-6"
          >{{ $t("register.user.proceed")
          }}<v-icon class="pl-2" x-small>$arrowForward</v-icon></v-btn
        >
        <v-btn
          v-else-if="step == totalStep"
          :loading="loading"
          @click="register"
          depressed
          block
          rounded
          large
          color="primary"
          class="col-6"
          >{{
            hasCard
              ? $t("register.user.signInCard")
              : $t("register.user.signIn")
          }}</v-btn
        >
      </v-col>
    </v-row>
    <RegistrationCompleted v-if="step == totalStep + 1" />
  </v-container>
</template>

<style lang="scss">
.registration-user-container {
  .card-img {
    max-width: 100%;
  }
  .v-image__image--cover {
    background-size: inherit;
  }
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 14px;
  }
  a {
    color: var(--v-default-base) !important;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 12px !important;
    }
    .v-card {
      height: auto;
    }
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
    border-left-width: 1px;
    margin-left: 20px;
  }
  .v-btn-toggle .v-btn.v-btn {
    border-radius: 28px !important;
    min-width: 80px;
    height: 45px !important;
    font-weight: 600;
    color: var(--v-default-base);
  }
  .v-btn-toggle--group > .v-btn.v-btn--active {
    background-color: $primary !important;

    .v-btn__content {
      color: $white;
    }
  }

  .v-btn-toggle--group > .v-btn.v-btn--active::before {
    opacity: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
  }
}
</style>

<script>
//import UserEmailAddress from "@/components/profile/form/UserEmailAddress";
import UserData from "@/components/profile/form/UserData";
import NewCustomer from "@/components/profile/form/NewCustomer.vue";
import PrivacySection from "@/components/profile/form/PrivacySection.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";

import categoryMixins from "~/mixins/category";
import resetPassword from "@/mixins/resetPassword";
import oneSignal from "@/mixins/oneSignal";
import Login from "@/components/Login.vue";
import RegistrationCompleted from "./RegistrationCompleted.vue";
import ConfirmMessage from "@/components/ConfirmMessage.vue";
import matomoService from "@/service/analytics/matomoService";

export default {
  name: "RegistrationUser",
  data() {
    return {
      response: {},
      hasCard: null,
      step: 0,
      totalStep: 3,
      userData: {},
      privacyData: {},
      chekingCard: false,
      loading: null
    };
  },
  mixins: [categoryMixins, resetPassword, oneSignal],
  components: {
    NewCustomer,
    ResponseMessage,
    UserData,
    PrivacySection,
    RegistrationCompleted
  },
  mounted(){
    matomoService.trackPageView("/signup")
  },
  methods: {
    async register() {
      this.response = {};
      let newCustomerDataForm = this.$refs.newCustomerDataForm.$refs.form.validate();
      let newCustomerPrivacyForm = this.$refs.newCustomerPrivacyForm.$refs.form.validate();
      if (newCustomerDataForm && newCustomerPrivacyForm) {
        this.loading = true;
        this.userData.acceptRegulation = "1";
        this.userData.token = await this.getOneSignalUUID();
        this.userData.disclaimerMarketing = this.privacyData.marketing;
        this.userData.disclaimerProfiling = this.privacyData.profilazione;
        
        this.userData.canaleMail = (this.privacyData.canaleMail && this.privacyData.marketing=="1" )?this.privacyData.canaleMail:"0";
        this.userData.canaleSms = (this.privacyData.canaleSms && this.privacyData.marketing=="1")?this.privacyData.canaleSms:"0";
        this.userData.canalePush = (this.privacyData.canalePush && this.privacyData.marketing=="1")?this.privacyData.canalePush:"0";
        if(this.userData.address == "" || this.userData.address == undefined){
          this.userData.address = "non specificato";
        }

        if(this.userData.addressNumber == "" || this.userData.addressNumber == undefined){
          this.userData.addressNumber = "non specificato";
        }
        
        AbbondanzaRegistrationService.registerUser(this.userData)
          .then(data => {
            console.log(data);
            if (data.response && data.response.status == 0) {
              this.$emit("submit", true);
              this.$dialog.show(RegistrationCompleted,{
                fullscreen:true,
                waitForResult: true,
                email:this.userData.email,
              })
              /* instead of going home, we just need to add a step
              this.$router.push({
                name: "Home"
              });*/
              this.loading = false;
              this.step += 1;
            } else {
              this.loading = false;
              this.response = data;
            }
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    goBack() {
      if (this.step > 0) {
        this.step += -1;
        if (this.step == 0) {
          this.hasCard = null;
        }
      }
    },
    async goForward() {
      let canGoOn = true;
      
      if (this.step == this.totalStep) {
        return;
      }
      let newCustomerDataForm = true;
      let newCustomerPrivacyForm = true;
      if (this.step == 1) {
        newCustomerDataForm = this.$refs.newCustomerDataForm.$refs.form.validate();
        console.log(this.userData.phone);
        if(this.userData.phone == undefined || this.userData.phone == ""){
          //let descr = `<h4 class='text-center'>Numero di cellulare</h4><p class='text-center'>Se deciderai di non fornirci questo dato, potremo comunicare con te solo attraverso la tua email.</p>`
/*
          let res = await this.$dialog.confirm({
            text: descr,
            actions: {
              true: {
                rounded: true,
                text: "Inserisci numero"
              },
              false: {
                color: "var(--v-primary-base)",
                rounded: true,
                text: "Ok, grazie!"
              },
            }
          });
          */
          let res = await this.$dialog.show(ConfirmMessage, {
              waitForResult: true,
              fullscreen: false,
              message: "Se deciderai di non fornirci questo dato, potremo comunicare con te solo attraverso la tua email.",
              title:"Numero di cellulare",
              button_yes:"Inserisci numero",
              button_no:"Ok, grazie!"
            });
          if(res){
            canGoOn = false;
            console.log("dont change page");
          }
        }
        //check general info
      } else if (this.step == 2) {
        //check password
        newCustomerDataForm = this.$refs.newCustomerDataForm.$refs.form.validate();
      } else if (this.step == 3) {
        //check privacy
        newCustomerPrivacyForm = this.$refs.newCustomerPrivacyForm.$refs.form.validate();
      }
      console.log(canGoOn)
      if (newCustomerDataForm && newCustomerPrivacyForm && canGoOn == true) {
        this.step += 1;
        this.$refs.newCustomerDataForm.$refs.form.resetValidation();
      }
      
    },

    checkUserCardData(data) {
      this.chekingCard = true;
      
      AbbondanzaRegistrationService.findUser(
        data.firstName,
        data.lastName,
        data.fidelity
      )
        .then(data => {
          if (data.response.status == 0) {
            this.response = {};
            this.userData = data.data;
            if((this.userData.city == undefined || this.userData.prov == undefined) && this.userData.cap != undefined){
              this.userData.cap=undefined;
            }
            this.privacyData.canaleMail = this.userData.channelMail;
            this.privacyData.canaleSms = this.userData.channelSms;
            this.privacyData.canalePush = this.userData.channelPush;
            this.privacyData.marketing = this.userData.disclaimerMarketing;
            this.privacyData.profilazione = this.userData.disclaimerProfiling;
            this.privacyData.hasCard = true;
            this.step += 1;
          } else {
            this.response = data.response;
          }
        })
        .finally(() => {
          this.chekingCard = false;
        });

    },
    resetUserData() {
      if(this.hasCard == true){
        this.step = 0;
      }else{
        this.step = 1;
      }
      this.userData = {};
    },
    async login() {
      this.$emit("submit", false);
      let res = await this.$dialog.show(Login, {
        waitForResult: true,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      if (res) {
        console.log("Reset password popup", res);
      }
    },
    openInBrowser(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
    goToPage(url) {
      this.$emit("submit", false);

      this.$router.push(url);
    }
  }
};
</script>
