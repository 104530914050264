<template>
  <div class="shortcuts-menu d-flex justify-center justify-sm-center mb-6 mt-3">
    <div class="item mx-1 mx-sm-2">
      <div
        class="img-wrap"
        v-ripple
        @click="$router.push(`/gala-world/magazine?article_class_id=1`)"
      >
        <img src="/img_layout/homepage_links/ricette.svg" />
      </div>
      <div class="label">{{ $t("shortcutsMenu.recipes") }}</div>
    </div>
    <div class="item mx-1 mx-sm-2">
      <div
        class="img-wrap"
        v-ripple
        @click="$router.push(`/gala-world/sostenibilita`)"
      >
        <img src="/img_layout/homepage_links/sostenibilita.svg" />
      </div>
      <div class="label">{{ $t("shortcutsMenu.sustainability") }}</div>
    </div>
    <div class="item mx-1 mx-sm-2">
      <div
        class="img-wrap"
        v-ripple
        @click="$router.push(`/gala-world/magazine?article_class_id=2`)"
      >
        <img src="/img_layout/homepage_links/news.svg" />
      </div>
      <div class="label">{{ $t("shortcutsMenu.news") }}</div>
    </div>
    <div class="item mx-1 mx-sm-2">
      <div
        class="img-wrap"
        v-ripple
        @click="$router.push(`/gala-world/contact-form`)"
      >
        <img src="/img_layout/homepage_links/contatti.svg" />
      </div>
      <div class="label">{{ $t("shortcutsMenu.contactUs") }}</div>
    </div>
  </div>
</template>
<style lang="scss">
.shortcuts-menu {
  margin-bottom: 50px !important;
  width: 100%;
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: calc(25% - 8px);
    max-width: 85px;
    aspect-ratio: 1/1;
    .img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 1;
      background-color: $secondary;
      border-radius: 50%;
      padding: 10px;
      overflow: hidden;
      cursor: pointer;
      flex: 0 0 auto;
      img {
        width: 100%;
        max-width: 45px;
        height: auto;
      }
    }
    @supports (-moz-appearance: none) {
      .img-wrap {
        flex: initial; /* Override the rule for Firefox */
      }
    }
  }
  .label {
    font-size: 12px;
    margin: 5px 2px;
    // min-height: 36px;
    text-align: center;
    flex-grow: 1;
  }
  // @media (max-width: 250px) {
  //   .label {
  //     min-height: 56px;
  //   }
  // }
}
</style>
<script>
export default {
  name: "ShortcutsMenu",
  computed: {}
};
</script>
